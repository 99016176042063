<template>
  <div class="content">
    <main id="e" class="flex-shrink-0 form-signin">
      <div class="content p-4 box-login shadow">
        <form @submit.prevent="Forgot()" method="post">
          <img class="mb-0" :src="require('@/assets/img/login.png')" style="width:50%;">
          <br>
          <img class="mb-1" :src="require('@/assets/img/brand.png')" style="width:50%;">
          <h4 class="text-color-1 mb-3 fw-bold">Enter your email to continue</h4>
          <div class="text-start">
            <label class="text-color-1">Email</label>
            <input type="text" v-model="forgot.email" class="form-control" id="email" placeholder="Email" required="required" @keyup="EnabledButton">
          </div>
          <button id="send-button" class="button-1 w-100 btn btn-primary" type="submit" disabled>Send</button>
          <hr>
          <div v-if="response.status == true" class="alert alert-success d-flex align-items-center" role="alert">
            <div>
              {{response.message}}
            </div>
          </div>
          <div v-if="response.status == false" class="alert alert-danger d-flex align-items-center" role="alert">
            <div>
              {{response.message}}
            </div>
          </div>
        </form>
        <div class="checkbox mb-3"></div>
        <span class="text-color-1">You have account? <router-link to="/login" class="link-color fw-bold">Login</router-link> 
        </span>
      </div>
      <p class="mt-5 mb-3 text-muted">© Work Mileage 2022  </p>
    </main>
  </div>
</template>

<script>
  import Api from '@/services/Api';

  export default 
  {
    data: () => (
    {
      forgot: 
      {
        email: ""
      },
      response: 
      {
        message: "",
        status: "Empty"
      },
      resultMsg: ""
    }),
    methods: 
    {
      Forgot() 
      {
        document.getElementById("send-button").disabled = true;
        Api.post("/login/forgot",this.forgot).then((result) => 
        {
          this.response.status = result.data.status;
          this.response.message =  result.data.msg;
          this.EnabledButton();

          this.$moshaToast( ''+this.response.message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
        })
      },
      //Enabled/Disabled send button
      EnabledButton()
      {
        let button = document.getElementById("send-button").disabled;
        let valueTextField = document.getElementById("email").value.trim();

        //Disabled true?
        if(button)
        {
          //I write and button is enabled 
          document.getElementById("send-button").disabled = (valueTextField == "");
        }
        else
        {
          //Is input empty?
          if(valueTextField == "")
          {
            //disabled button
            document.getElementById("send-button").disabled = (valueTextField == "");
          }
        }
      }
    }
  }
</script>

<style scoped>
.link-color
{
  color: var(--color-three);
  text-decoration: none;
}
.content 
{
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-login
{
    background-color: var(--color-two);  
    border-radius: 5px;  
}
.text-color-1
{
    color:var(--color-one);
}
.form-signin 
{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.button-1
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}
.button-1:hover
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}

.form-signin .checkbox 
{
  font-weight: 400;
}

.form-signin input[type="text"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="email"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="password"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}
.bd-placeholder-img 
{
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) 
  {
    .bd-placeholder-img-lg 
    {
      font-size: 3.5rem;
    }
  }

.h4, h4 
{
    font-size: calc(1rem + .3vw);
}
</style>
