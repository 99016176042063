<template>
     <Forgot/>
</template>

<script>
// @ is an alias to /src
import Forgot from '@/components/login/Forgot.vue'

export default 
{

  components: 
  {
    Forgot
  }
}
</script>
